import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import {
	Navbar,
	NavbarBrand,
	Collapse,
	Nav,
	NavItem,
	NavLink,
	Button,
	Modal,
	ModalBody,
	Input,
	Form,
	DropdownItem,
	NavbarText,
	UncontrolledDropdown,
	DropdownToggle,
	NavbarToggler,
	DropdownMenu,
	Tooltip,
} from 'reactstrap';
import logo1 from '../assets/logo1.png';
import { connectWallet } from '../utils/web3';
import AddressBar from './AddressBar';

function SecondHeader({ showNav }) {
	const state = useSelector(state => state);
	let [collapsed, setCollapsed] = useState(false);
	let [tooltipOpen, setTooltipOpen] = useState(false);
	let [tooltipOpen2, setTooltipOpen2] = useState(false);

	const toggle1 = () => {
		setTooltipOpen(!tooltipOpen);
	};
	const toggle2 = () => {
		setTooltipOpen2(!tooltipOpen2);
	};

	const toggle = () => {
		setCollapsed(!collapsed);
	};

	const connect = () => {
		connectWallet();
	};

	return (
		<>
			<div className={`${!showNav ? 'fixed-nav-bar' : 'nav_bg'}`}>
				{showNav && <AddressBar />}
				<Navbar expand='lg' className='second_navbar'>
					<NavbarBrand href='/' className='ms-0  ms-lg-5'>
						<img src={logo1} className='navbar_second_logo' />
					</NavbarBrand>
					<NavbarToggler
						className='second_navbar_toggler me-2 me-sm-3'
						onClick={toggle}
					>
						{collapsed ? (
							<i
								aria-hidden='true'
								tabindex='0'
								className='far fa-window-close'
							></i>
						) : (
							<i class='fa fa-list'></i>
						)}
					</NavbarToggler>
					<Collapse
						navbar
						isOpen={collapsed}
						// className={`${collapsed && 'toggle_background'}`}
						className='nav_collapse '
					>
						<Nav
							className='d-flex me-auto align-items-center'
							navbar
						>
							<NavItem className='mx-lg-3'>
								<NavLink href='/launchpad' id='TooltipExample'>
									STAKING
								</NavLink>
								<Tooltip
									placement='bottom'
									isOpen={tooltipOpen}
									target='TooltipExample'
									toggle={toggle1}
								>
									Coming Soon
								</Tooltip>
							</NavItem>

							<NavItem className='mx-lg-3'>
								<NavLink href='/' id='TooltipExample2'>
									PROJECTS
								</NavLink>
								<Tooltip
									placement='bottom'
									isOpen={tooltipOpen2}
									target='TooltipExample2'
									toggle={toggle2}
								>
									Coming Soon
								</Tooltip>
							</NavItem>
						</Nav>

						<Nav
							className='d-flex ms-auto  me-lg-5  align-items-center'
							navbar
						>
							<NavItem className=''>
								{/* <NavLink href='/' className=''> */}
								<Button
									className='connect_btn'
									onClick={connect}
								>
									{state.web3Reducer.shortAddress
										? state.web3Reducer.shortAddress
										: 'CONNECT WALLET'}
								</Button>
								{/* </NavLink> */}
							</NavItem>
						</Nav>
						<Nav
								className='d-flex  align-items-start align-items-lg-center'
								navbar
							>
								<NavItem className=''>
									{state.web3Reducer.shortAddress &&
										<NavLink href='/'>{state.web3Reducer.balance} SP</NavLink>
									}
								</NavItem>
							</Nav>

						{/* <Nav
            className='d-flex justify-content-center align-items-center'
            navbar
        >
            <NavItem>
                <NavLink href='/'>LAUNCHPAD</NavLink>
            </NavItem>

            <NavItem>
                <NavLink href='/'>GUILD</NavLink>
            </NavItem>
            <NavItem>
                <NavLink href='/'>METAVERSE</NavLink>
            </NavItem>
            <NavItem>
                <NavLink href='https://github.com/reactstrap/reactstrap'>
                    <img src={logo1} className='navbar__logo' />
                </NavLink>
            </NavItem>
            <NavItem>
                <NavLink href='/'>Documentation</NavLink>
            </NavItem>
            <NavItem>
                <NavLink href='/'>STAKING</NavLink>
            </NavItem>
            <NavItem className='d-block d-lg-none'>
                <NavLink href='/' className='d-none d-md-block'>
                    LAUNCH APP
                </NavLink>
            </NavItem>
            <NavItem className='d-none d-lg-block'>
                <NavLink href='/' className='d-none d-md-block'>
                    <Button className='launch_btn'>
                        LAUNCH APP
                    </Button>
                </NavLink>
            </NavItem>
        </Nav> */}
					</Collapse>
				</Navbar>
			</div>
		</>
	);
}

export default SecondHeader;
