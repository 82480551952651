import React from 'react';
import { Fade, Zoom } from 'react-reveal';
import { Col, Container, Row } from 'reactstrap';
import MainPageChart from '../assets/Main-Page-Chart.png';

function Metaverse() {
	return (
		<div className='metaverse_section p-3'>
			<Container fluid>
				<Row>
					<Col className='mt-2'>
						<Zoom>
							<p className='background_text'>METAVERSE</p>
						</Zoom>
					</Col>
				</Row>
				<Row>
					<Col className='metaverse_sub_heading'>
						<Fade bottom>
							<p>
								<span className='sub_heading'>STEAMPAD</span>{' '}
								{/* <br className='d-block d-md-none' /> a True */}
								Metaverse Platform
							</p>
						</Fade>
					</Col>
				</Row>
				<Row className='justify-content-center metaverse_row mt-4 mt-md-0'>
					<Col
						md='6'
						className=' d-flex justify-content-center align-items-center'
					>
						<Fade left>
							<p className='metaverse_p'>
								We’re bringing the experience of Blockchain
								Gaming, NFTs & Metaverses to millions of people.
								And we invite you to be part of our vision.
								<br />
								Steampad focus is to bring the complete
								Metaverse ecosystem at one place by introducing
								the $STPD Token which will be the native token
								and the central part of our ecosystem, SteamPad
								Token will be used to access a wide range of
								products starting with our Launchpad, Gaming
								Guild and P2E Games.
							</p>
						</Fade>
					</Col>
					<Col
						md='6'
						className='d-flex justify-content-center align-items-center'
					>
						<img src={MainPageChart} className='chart_img' />
					</Col>
				</Row>
			</Container>
		</div>
	);
}

export default Metaverse;
