import React from 'react';

function AdressBar() {
	return (
		<div className='token_adress_bar d-flex align-items-center px-2 px-md-3'>
			<div className='d-flex align-items-center '>
				<span className='contract_address'>Contract Address:</span>
				<a
					className='ms-2 contract_link'
					href='https://bscscan.com/address/0xA4358D9F98788518e31816c447Aa3BAaE3E36dBd#code'
					target='_blank'
				>
					0xA4358...E36dBd
				</a>
				<i
					className='ms-2 fas fa-copy'
					onClick={() =>
						navigator.clipboard.writeText(
							'https://bscscan.com/address/0xA4358D9F98788518e31816c447Aa3BAaE3E36dBd#code'
						)
					}
				></i>
			</div>
		</div>
	);
}

export default AdressBar;
