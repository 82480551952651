import React from 'react';
import { Container, Row, Col } from 'reactstrap';
import token_main from '../assets/token_main.png';
import logo from '../assets/logo.png';
import stpd_token from '../assets/stpd_token.png';
import stats from '../assets/stats.png';
import token_allocation from '../assets/token_allocation.png';
import pie_chart from '../assets/pie_chart.png';
import { Fade, Zoom } from 'react-reveal';
function Tokenomics() {
	return (
		<div className='token_section px-3'>
			<Container fluid>
				<Row>
					<Col className='mt-5'>
						<Zoom>
							<p className='background_text'>TOKENOMICS</p>
						</Zoom>
					</Col>
				</Row>
				<Row>
					<Col className='metaverse_sub_heading'>
						<p>
							<span className='sub_heading'>STEAMPAD</span>{' '}
							Tokenomics
						</p>
					</Col>
				</Row>
				<Row className='d-none d-md-block'>
					<Col>
						<img src={token_main} className='token_main_img' />
					</Col>
				</Row>
				<div className='d-flex justify-content-center align-items-center flex-column mt-4 mt-md-0 token_col d-md-none'>
					<h2 className='spd_h2'>$SPD</h2>
					<h2 className='tokenomic_h2'>TOKENOMICS</h2>
					<img src={logo} className='token_logo_img mt-3' />
					<h2 className='tokenomic_h2'>TOKEN SUPPLY</h2>
					<div className='token__value mt-4'>
						<h2 className='token_value_h2 mt-2'>1,000,000,000</h2>
					</div>
					<img src={stpd_token} className='stpd_img mt-5' />
					<img src={stats} className='stpd_img mt-3' />
					<img src={token_allocation} className='stpd_img mt-3' />
					<img src={pie_chart} className='stpd_img mt-3' />
				</div>
			</Container>
		</div>
	);
}

export default Tokenomics;
