import React, { useState, useEffect, useRef } from 'react';
import Header from '../components/Header';
import '../App.css';
import Steampad from '../components/Steampad';
import Metaverse from '../components/Metaverse';
import Ecosystem from '../components/Ecosystem';
import Roadmap from '../components/Roadmap';
import Tokenomics from '../components/Tokenomics';
import Manifesto from '../components/Manifesto';
import JoinUs from '../components/JoinUs';
import Footer from '../components/Footer';
import PowerToken from '../components/PowerToken';
import PurchaseToken from '../components/PurchaseToken';

function Home() {
	const myRef = useRef(null);

	const executeScroll = () => myRef.current.scrollIntoView();
	const [showNav, setShowNav] = useState(true);

	const hideNav = () => {
		if (window.scrollY >= 80) {
			setShowNav(false);
		} else {
			setShowNav(true);
		}
	};

	window.addEventListener('scroll', hideNav);

	return (
		<div className='main'>
			<Header
				type={'home'}
				showNav={showNav}
				executeScroll={executeScroll}
			/>
			<Steampad />
			<Metaverse />
			<Ecosystem />
			<div className='roadmap_token'>
				<Roadmap />
				<Tokenomics />
			</div>
			<PowerToken />
			{/* <Manifesto /> */}
			{/* <JoinUs /> */}
			<Footer />
		</div>
	);
}

export default Home;
