import React, { useState, useEffect } from 'react';
import { Container } from 'reactstrap';
import Banner from '../components/Banner';
import Distribution from '../components/Distribution';
import Footer from '../components/Footer';
import Header from '../components/Header';

import SecondHeader from '../components/SecondHeader';
import Trading from '../components/Trading';
import Tier from '../components/Tier';
import Manifesto from '../components/Manifesto';
import TokenCommit from '../components/TokenCommit';
import IDO from '../components/IDO';
import tier_ranking from '../assets/tier_ranking.png';

function Launchpad() {
	const [showNav, setShowNav] = useState(true);

	const hideNav = () => {
		if (window.scrollY >= 80) {
			setShowNav(false);
		} else {
			setShowNav(true);
		}
	};

	window.addEventListener('scroll', hideNav);
	return (
		<div>
			<SecondHeader showNav={showNav} />
			<div className=''>
				<Banner />
				<Distribution />

				<div className='launchpad_sub_section'>
					<TokenCommit />
					<Container className='pt-5 pb-5 '>
						<img src={tier_ranking} className='tier_small_img' />
					</Container>
					<IDO />
					<Tier />
				</div>

				<Manifesto />
			</div>
			<Footer />
		</div>
	);
}

export default Launchpad;
