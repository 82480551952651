import React from 'react';
import { Container, Row, Col } from 'reactstrap';
import Slide from 'react-reveal/Slide';
import { Fade, Zoom } from 'react-reveal';

function Ecosystem() {
	return (
		<div className='ecosystem_section px-3'>
			<Container>
				<Row>
					<Col className='mt-3'>
						<Zoom>
							<p className='background_text'>ECOSYSTEM</p>
						</Zoom>
					</Col>
				</Row>
				<Row>
					<Col className='metaverse_sub_heading'>
						<Fade bottom>
							<p>
								<span className='sub_heading'>STEAMPAD</span>{' '}
								Ecosystem
							</p>
						</Fade>
					</Col>
				</Row>

				<Row className='no-gutters d-none d-md-flex'>
					<Col md='6' className='p-0'>
						<Fade left>
							<div className='eco_col p-3 d-flex justify-content-center flex-column align-items-center'>
								<i class='fa fa-rocket'></i>
								<h5 className='mt-2'>Launchpad</h5>
								<p>
									A decentralized multi-chain fundraising
									platform enabling projects to raise capital
									for new crypto projects in their
									early-stages and projects are able to
									onboard a significant community to support
									and promote their new venture through an
									IDO.
								</p>
							</div>
						</Fade>
					</Col>
					<Col md='6' className='p-0'>
						<Fade right>
							<div className='eco_col__right_img'></div>
						</Fade>
					</Col>

					<Col md='6' className='p-0'>
						<Fade left>
							<div className='eco_col__left_img'></div>
						</Fade>
					</Col>
					<Col md='6' className='p-0'>
						<Fade right>
							<div className='eco_col p-3 d-flex justify-content-center flex-column align-items-center'>
								<i class='fa fa-gamepad'></i>
								<h5 className='mt-2'>Game Guild</h5>
								<p>
									Steampad combines the best of the
									non-fungible token (NFT) and the Play 2 Earn
									Games space, bringing yield farming to game
									economies while adding value to these
									virtual worlds by developing the content and
									economy of these games.
								</p>
							</div>
						</Fade>
					</Col>
					<Col md='6' className='p-0'>
						<Fade left>
							<div className='eco_col p-3 d-flex justify-content-center flex-column align-items-center'>
								<i class='fa fa-tachometer-alt'></i>
								<h5 className='mt-2'>Metaverse</h5>
								<p>
									We are developing an entire digital economy,
									Will have our own Streaming platform in a
									virtual environment – which will help the
									competitive gamers, the artists, and content
									creators that populate the metaverse. Very
									soon will have our own Play 2 Earn game
									which will connect our metaverse ecosystem
									along with a new utility token launched.
								</p>
							</div>
						</Fade>
					</Col>
					<Col md='6' className='p-0'>
						<Fade right>
							<div className='eco_col__right2_img'></div>
						</Fade>
					</Col>
				</Row>

				<Row className='no-gutters d-block d-md-none mt-4'>
					<Col md='6' className='p-0'>
						<Fade right>
							<div className='eco_col__right_img'></div>
						</Fade>
					</Col>
					<Col md='6' className='p-0'>
						<Fade left>
							<div className='eco_col p-2 d-flex justify-content-center flex-column align-items-center'>
								<i class='fa fa-rocket'></i>
								<h5 className='mt-2'>Launchpad</h5>
								<p>
									A decentralized multi-chain fundraising
									platform enabling projects to raise capital
									for new crypto projects in their
									early-stages and projects are able to
									onboard a significant community to support
									and promote their new venture through an
									IDO.
								</p>
							</div>
						</Fade>
					</Col>

					<Col md='6' className='p-0'>
						<Fade left>
							<div className='eco_col__left_img'></div>
						</Fade>
					</Col>

					<Col md='6' className='p-0'>
						<Fade right>
							<div className='eco_col p-2  d-flex justify-content-center flex-column align-items-center'>
								<i class='fa fa-gamepad'></i>
								<h5 className='mt-2'>Game Guild</h5>
								<p>
									Steampad combines the best of the
									non-fungible token (NFT) and the Play 2 Earn
									Games space, bringing yield farming to game
									economies while adding value to these
									virtual worlds by developing the content and
									economy of these games.
								</p>
							</div>
						</Fade>
					</Col>
					<Col md='6' className='p-0'>
						<Fade right>
							<div className='eco_col__right2_img'></div>
						</Fade>
					</Col>
					<Col md='6' className='p-0'>
						<Fade left>
							<div className='eco_col p-2 d-flex justify-content-center flex-column align-items-center'>
								<i class='fa fa-tachometer-alt'></i>
								<h5 className='mt-2'>Metaverse</h5>
								<p>
									We are developing an entire digital economy,
									Will have our own Streaming platform in a
									virtual environment – which will help the
									competitive gamers, the artists, and content
									creators that populate the metaverse. Very
									soon will have our own Play 2 Earn game
									which will connect our metaverse ecosystem
									along with a new utility token launched.
								</p>
							</div>
						</Fade>
					</Col>
				</Row>
			</Container>
		</div>
	);
}

export default Ecosystem;
