import React from 'react';
import { Col, Container, Row } from 'reactstrap';
import logo_black from '../assets/logo_black.png';
import logo_white from '../assets/logo_white.png';

function Footer() {
	return (
		<footer>
			<Container fluid>
				<Row className='footer_row  no-gutters pt-4 pb-1'>
					<Col md='4'>
						{' '}
						<img src={logo_white} className='footer_img' alt='' />
					</Col>
					<Col md='4'>
						<h2>About</h2>
						<br />
						<a href='#'>Overview</a>
						<br />
						<a href='#'>Ecosystem</a>
						<br />
						<a href='#'>Roadmap</a>
						<br />
						<a href='#'>Tokenomics</a>
						<br />
						<a href='#'>Manifesto</a>
					</Col>
					<Col md='4' className='d-flex flex-column'>
						<div className='d-flex  align-items-center'>
							<div className='footer_icon me-3'>
								<i class='fab fa-twitter'></i>
							</div>
							<a
								href='https://twitter.com/Steampad'
								target='_blank'
							>
								Follow us on Twitter
							</a>
						</div>
						<br />

						<div className='d-flex align-items-center'>
							<div className='footer_icon me-3'>
								<i class='fab fa-telegram'></i>
							</div>
							<a
								href='https://t.me/SteamPad_official_group'
								target='_blank'
							>
								Join us on Telegram
							</a>
						</div>
					</Col>
				</Row>

				<Row className='copy_row justify-content-center align-items-center'>
					<Col className='py-2 d-flex justify-content-center align-items-center'>
						<p className='copy_p'>
							All Right Reserved By Steampad.org
						</p>
					</Col>
				</Row>
			</Container>
		</footer>
	);
}

export default Footer;
