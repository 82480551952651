import React from 'react';
import { Row, Col, Container } from 'reactstrap';

import staking from '../assets/staking.png';
import game_fi from '../assets/game_fi.png';
import steam_marketplace from '../assets/steam_marketplace.png';
import streaming from '../assets/streaming.png';
import yeild_guild from '../assets/yeild_guild.png';
import launch from '../assets/launch.png';

function PowerToken() {
	return (
		<div className='power_token_section py-4'>
			<Container>
				<p className='heading'>POWER OF STEAMPAD TOKEN</p>

				<Row className='mt-4'>
					<Col
						md='4'
						align='center'
						className='mt-3 mt-md-5  px-md-4'
					>
						{/* <div className='icon_div mb-3'>
							<i className='fa fa-gamepad'></i>
						</div> */}
						<img src={game_fi} className='power_token_img mb-2' />
						<br />

						<span className='sub_heading '>Game-Fi Experience</span>
						<hr />
						<p className='mt-3 sub_heading_text'>
							SteampPad will have own blockchain Games where $STPD
							Tokens will be used in our P2E games to buy
							Avatars,Weapons and more items.
						</p>
					</Col>

					<Col
						md='4'
						align='center'
						className='mt-3 mt-md-5  px-md-4'
					>
						<img src={launch} className='power_token_img mb-2' />
						<br />
						{/* <div className='icon_div mb-3'>
							<i className='fa fa-rocket'></i>
						</div> */}

						<span className='sub_heading'>Launchpad</span>
						<hr />
						<p className='mt-3 sub_heading_text'>
							$STPD can be used to participate in the IDO on our
							platform and to increase the Tier System to gain
							more benefits.
						</p>
					</Col>

					<Col md='4' align='center' className='mt-3 mt-md-5 px-md-4'>
						{/* <div className='icon_div mb-3'>
							<i className='fa fa-gamepad'></i>
						</div> */}

						<img
							src={yeild_guild}
							className='power_token_img mb-2'
						/>
						<br />
						<span className='sub_heading'>Yield Guild</span>
						<hr />
						<p className='mt-3 sub_heading_text'>
							SteamPad will focus on investing in current and
							future NFT assets within games and lend the NFTs
							back to their guild members to optimize yield.
						</p>
					</Col>

					<Col
						md='4'
						align='center'
						className='mt-3 mt-md-5  px-md-4'
					>
						{/* <div className='icon_div mb-3'>
							<i className='fa fa-tv'></i>
						</div> */}
						<img src={streaming} className='power_token_img mb-2' />
						<br />
						<span className='sub_heading'>Streaming</span>
						<hr />
						<p className='mt-3 sub_heading_text'>
							$STPD Tokens can be used to send tips to their
							favourite streamers and content creators, also
							courses or content can be purchased related to
							blockchain for educational purpose.
						</p>
					</Col>

					<Col
						md='4'
						align='center'
						className='mt-3 mt-md-5  px-md-4'
					>
						{/* <div className='icon_div mb-3'>
							<i className='fa fa-gamepad'></i>
						</div> */}
						<img
							src={steam_marketplace}
							className='power_token_img mb-2'
						/>
						<br />
						<span className='sub_heading'>Steam MarketPlace</span>
						<hr />
						<p className='mt-3 sub_heading_text'>
							$STPD Tokens can be used to purchase in-game items
							of Steam marketplace with discounted prices and more
							option to come.
						</p>
					</Col>

					<Col
						md='4'
						align='center'
						className='mt-3 mt-md-5  px-md-4'
					>
						{/* <div className='icon_div mb-3'>
							<i className='fa fa-gamepad'></i>
						</div> */}
						<img
							src={staking}
							alt=''
							className='power_token_img mb-2'
						/>
						<br />
						<span className='sub_heading '>Staking</span>
						<hr />
						<p className='mt-3 sub_heading_text'>
							With staking $STPD, users can receive rewards and
							participate in the work of governance.
						</p>
					</Col>
				</Row>

				{/* <Row className='mt-4'>
					<Col md='4' align='left' className='px-3 px-md-5'>
						<div className='heading_div'>
							<span>Game-Fi Experience</span>
							<div className='ribbon'></div>
						</div>
						<p className='mt-3 sub_heading'>
							SteampPad will have own blockchain Games where $STPD
							Tokens will be used in our P2E games to buy
							Avatars,Weapons and more items.
						</p>
					</Col>
					<Col md='4' align='left' className='px-3 px-md-5'>
						<div className='heading_div'>
							<span>Launchpad</span>
						</div>
						<p className='mt-3 sub_heading'>
							$STPD can be used to participate in the IDO on our
							platform and to increase the Tier System to gain
							more benefits.
						</p>
					</Col>
					<Col md='4' align='left' className='px-3 px-md-5'>
						<div className='heading_div'>
							<span>Yield Guild</span>
						</div>
						<p className='mt-3 sub_heading'>
							SteamPad will focus on investing in current and
							future NFT assets within games and lend the NFTs
							back to their guild members to optimize yield.
						</p>
					</Col>

					<Col md='4' align='left' className='px-3 px-md-5'>
						<div className='heading_div'>
							<span>Streaming</span>
						</div>
						<p className='mt-3 sub_heading'>
							$STPD Tokens can be used to send tips to their
							favourite streamers and content creators, also
							courses or content can be purchased related to
							blockchain for educational purpose.
						</p>
					</Col>

					<Col md='4' align='left' className='px-3 px-md-5'>
						<div className='heading_div'>
							<span>Steam MarketPlace</span>
						</div>
						<p className='mt-3 sub_heading'>
							$STPD Tokens can be used to purchase in-game items
							of Steam marketplace with discounted prices and more
							option to come.
						</p>
					</Col>

					<Col md='4' align='left' className='px-3 px-md-5'>
						<div className='heading_div'>
							<span>Staking</span>
						</div>
						<p className='mt-3 sub_heading'>
							With staking $STPD, users can receive rewards and
							participate in the work of governance
						</p>
					</Col>
				</Row> */}
			</Container>
		</div>
	);
}

export default PowerToken;
