import React, { useState, useEffect } from 'react';
import { Container, Row, Col } from 'reactstrap';
import logo from '../assets/logo.png';
import Slide from 'react-reveal/Slide';

function Steampad() {
	return (
		<div className='steampad_section'>
			<Container>
				<div className='d-flex justify-content-center align-items-center main_row'>
					<img src={logo} className='me-md-2' />
					<h3 className='steampad_section_heading '>STEAMPAD</h3>
				</div>

				<Container className='mt-4 px-0 px-md-5'>
					<Slide left>
						<p className='steampad_sub_heading'>
							SteamPad brings the complete Metaverse platform for
							blockchain gamers by introducing Launchpad,Yield
							Guild and Play to Earn Games all at one place.
						</p>
					</Slide>
				</Container>
			</Container>
		</div>
	);
}

export default Steampad;
