import Web3 from 'web3';
import Web3Modal from 'web3modal';
import WalletConnectProvider from "@walletconnect/web3-provider";
import { store } from '../redux/store'
import { config } from './config';
import { connect, setTokenBalance } from '../redux/web3Slice';
import { SteampadABI } from './SteampadABI.js'

let web3;
export const connectWallet = async () => {
    try {
        const state = store.getState();
        const address = state.web3Reducer.walletAddress;
        if (!address) {
            console.log('Connecting to wallet');
            // /*
            const providerOptions = {
                walletconnect: {
                    package: WalletConnectProvider,
                    options: {
                        bridge: "https://bridge.walletconnect.org",
                        // chainId: 137, //137 for mainnet
                        chainId: config.chainId, //137 for mainnet
                        // network: "matic", //matic for mainnet
                        network: "Binance Smart Chain", //matic for mainnet
                        rpc: {
                            //    137: "https://polygon-mainnet.g.alchemy.com/v2/pSrFoRSBbhRQHF_ZcS5iffjXfe9s4DiD", //https://polygon-mainnet.g.alchemy.com/v2/QViRpMcPK99l3CnB-CjEOv1rFbzZXON9
                            56: config.rpcUrl, //https://polygon-mainnet.g.alchemy.com/v2/QViRpMcPK99l3CnB-CjEOv1rFbzZXON9
                        },
                    }
                },
            };

            console.log("Provider is", providerOptions)

            const web3Modal = new Web3Modal({
                network: "matic",
                cacheProvider: false, // optional
                providerOptions, // required
                disableInjectedProvider: false, // optional. For MetaMask / Brave / Opera.
            });

            console.log("Web3Modal instance is", web3Modal);
            const provider = await web3Modal.connect();



            provider.on("accountsChanged", async (accounts) => {
                console.log("Accounts", accounts)
                store.dispatch(connect(accounts[0]));
                const steamlandContract = new web3.eth.Contract(SteampadABI,config.contractAddress);
                const balance = await steamlandContract.methods.balanceOf(accounts[0]).call();
                const balanceInEth = web3.utils.fromWei(balance,"ether");
                store.dispatch(setTokenBalance({
                    balance:balance,
                    balanceInEth:balanceInEth,
                }))
            });

            web3 = new Web3(provider);
 

            console.log("Web3 instance is", web3);

            const chainid = await web3.eth.getChainId();

            console.log(chainid);
            if (chainid != config.chainId) {
                alert(`Please connect to ${config.networkName}`)
                return;
            }
            const accounts = await web3.eth.getAccounts();
            console.log("Acount is", accounts[0]);
            store.dispatch(connect(accounts[0]));
            const steamlandContract = new web3.eth.Contract(SteampadABI,config.contractAddress);
            const balance = await steamlandContract.methods.balanceOf(accounts[0]).call();
            const balanceInEth = web3.utils.fromWei(balance,"ether");
            store.dispatch(setTokenBalance({
                balance:balance,
                balanceInEth:balanceInEth,
            }))
        } else {
            console.log('Already connected')
        }
    } catch (err) {
        console.log(err);
    }
}